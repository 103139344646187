<template>
  <div>
    <a-modal
      v-model="visible"
      :title="treeTitle"
      :mask-closable="false"
      :footer="null"
      :z-index="9999"
      @cancel="closeVisibleTree"
    >
      <div class="department_tree">
        <!--   左侧tree   -->
        <div class="department_tree_father department_tree_father_one">
          <!--   搜索框   -->
          <div class="search_class">
            <Search ref="searchValueRef" v-model="searchValue" :placeholder="placeholder" @focus="focus" @getSearchValue="getSearchValueData" @visibleCallback="visibleCallback" />
          </div>
          <!--   搜索结果页   -->
          <div v-if="searchResultVisible" class="search_result">
            <SearchResult :search-result-list="searchResultListData" :menu-list-data="menuListData" @getSearchResultData="getSearchResultData" />
          </div>
          <div v-else>
            <!--   左侧tree   -->
            <div class="tree_class">
              <Tree
                :tree-data-list="treeDataList"
                :menu-list-data-list="menuListDataList"
                @getRightMenu="getRightMenu"
              />
            </div>
          </div>
        </div>
        <!--   右侧菜单   -->
        <div class="department_tree_father">
          <DataList
            :menu-list-data="menuListData"
            @delCallBack="delCallBack"
            @delAllCallBack="delAllCallBack"
            @closeModel="closeVisibleTree"
            @perModelConfirm="perModelConfirm"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getOrgStaffTree, getStaffVague } from '@/services/dropDownInput/index.js'
import { getTypeJudgment, getTree, getDataEcho } from './tree/index.js'
import Search from './sarch/index.vue'
import Tree from './tree/index.vue'
import DataList from './dataList/index.vue'
import SearchResult from './searchResult/index.vue'
export default {
  name: 'PerModelSelect',
  components: {
    Search,
    Tree,
    DataList,
    SearchResult
  },
  model: {
    prop: 'perModelSelectVisible',
    event: 'getPerModelSelectVisible'
  },
  props: {
    perModelSelectVisible: {
      type: Boolean,
      default: false
    },
    treeTitle: {
      type: String,
      default: '人员选择'
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    dataEcho: {
      type: Array,
      default: () => []
    },
    getPerDataList: {
      type: Function,
      default: null
    },
    searchValueList: {
      type: Function,
      default: null
    },
    // 判断是否请求 已离职 人员
    queryLeavePerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      searchResultVisible: false, // 搜后内容展示区
      searchValue: '', // 搜索绑定
      menuListData: [], // 联动&&回显
      menuListDataList: [],
      searchResultListData: [], // 搜索结果列表
      searchMenuDataList: [],
      treeDataList: []
    }
  },
  watch: {
    perModelSelectVisible(val) {
      this.visible = val
      if (val) {
        if (!this.dataEcho) return
        this.menuListData = []
        this.menuListDataList = []
        for (let i = 0; i < this.dataEcho.length; i++) {
          this.menuListData.push(getDataEcho(this.treeDataList, this.dataEcho[i].onJobId))
          this.menuListDataList.push(getDataEcho(this.treeDataList, this.dataEcho[i].onJobId))
        }
      }
    }
    // dataEcho: {
    //   handler(val) {
    //     this.menuListData = []
    //     this.menuListDataList = []
    //     for (let i = 0; i < val.length; i++) {
    //       this.menuListData.push(getDataEcho(this.treeDataList, val[i].onJobId))
    //       this.menuListDataList.push(getDataEcho(this.treeDataList, val[i].onJobId))
    //     }
    //   },
    //   deep: true
    // }
  },
  mounted() {
    this.getOrgStaffTree()
  },
  methods: {
    async getOrgStaffTree() {
      const res = await getOrgStaffTree({ scope: true, leaveStaff: this.queryLeavePerson })
      this.treeDataList = this.getDataProcessing(res.data[0].children)
    },
    // 处理数据，将人员添加children中
    getDataProcessing(data) {
      data.forEach(item => {
        if (item.staffList && item.staffList.length > 0) {
          item.children.push(...item.staffList)
        }
        if (item.children && item.children.length > 0) {
          this.getDataProcessing(item.children)
        }
      })
      return data
    },
    // 搜索鼠标移入
    focus() {
      this.searchResultVisible = true
      this.searchMenuDataList = this.menuListData
    },
    // 关闭回调
    visibleCallback() {
      this.searchResultVisible = false
      this.searchResultListData = []
    },
    // 搜索
    async getSearchValueData(val) {
      const data = await this.searchValueList ? this.searchValueList(val) : this.getStaffVague()
      if (val) {
        const res = await data
        this.searchResultListData = getTree(res)
      } else {
        this.searchResultListData = []
      }
    },
    // 获取搜索结果数据
    async getStaffVague() {
      const res = await getStaffVague({ inputValue: this.searchValue, leaveStaff: this.queryLeavePerson })
      return getTree(res.data)
    },
    // 搜索页关联菜单
    getSearchResultData(data, checked) {
      if (checked) {
        this.menuListData.push(data)
        this.menuListDataList.push(data)
      } else {
        this.menuListData = this.menuListData.filter(item => item.staffId !== data.staffId)
        this.menuListDataList = this.menuListData.filter(item => item.staffId !== data.staffId)
      }
    },
    // 选中右侧菜单展示
    getRightMenu(data, type) {
      if (type === 'peo') {
        this.menuListData.push(data)
      } else if (type === 'dep') {
        this.menuListData.push(...getTypeJudgment(data))
      } else if (type === 'peoDel') {
        this.menuListData = this.menuListData.filter(item => item.staffId !== data)
      } else if (type === 'depDel') {
        this.menuListData = this.menuListData.filter(function(item) {
          return getTypeJudgment(data).indexOf(item) === -1
        })
      } else {
        this.menuListData = []
      }
      this.menuListData = this.dropDuplicate(this.menuListData)
    },
    // 选中人员去重
    dropDuplicate(data) {
      const arr = []
      const obj = []
      data.forEach((item) => {
        if (!obj[item.onJobId]) {
          arr.push(item)
          obj[item.onJobId] = 1
        }
      })
      return arr
    },
    // 个体删除
    delCallBack(id) {
      this.menuListDataList = this.menuListData.filter(item => item.staffId !== id)
      this.menuListData = this.menuListData.filter(item => item.staffId !== id)
    },
    // 全部清除
    delAllCallBack() {
      this.menuListDataList = []
      this.menuListData = []
    },
    // 确认
    perModelConfirm() {
      this.$refs.searchValueRef.visibleCallback()
      this.$emit('perModelConfirm', this.menuListData)
    },
    // 关闭弹窗
    closeVisibleTree() {
      this.visible = false
      this.$refs.searchValueRef.visibleCallback()
      this.$emit('getPerModelSelectVisible', this.visible)
    },
    // 清空
    clear() {
      this.visibleCallback()
      this.delAllCallBack()
    }
  }
}
</script>

<style lang="less" scoped>
  .department_tree {
    display: flex;
    flex-direction: row;
    .department_tree_father_one {
      border-right: 1px rgba(143, 147, 161, 0.30000001192092896) solid;
    }
    .department_tree_father {
      width: 50%;
      height: 566px;
      padding: 18px 24px;
      box-sizing: border-box;
      .search_class {
        width: 100%;
      }
      .search_result {
        overflow: auto;
        width: 100%;
        height: 492px;
        margin-top: 16px;
      }
      .tree_class {
        overflow: auto;
        width: 100%;
        height: 450px;
        margin-top: 5px;
      }
    }
  }
  /deep/ .ant-modal {
    width: 915px !important;
  }
  /deep/ .ant-modal-body {
    height: 566px;
    margin: 0;
    padding: 0;
  }
</style>
