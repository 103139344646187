<template>
  <div>
    <Modal
      :closable="true"
      :size="'normal'"
      :mask-closable="false"
      :visible="passwordVisible"
      :title="'修改密码'"
      @ok="handleSubmit"
      @cancel="onClose"
    >
      <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="原密码">
          <a-input-password
            v-decorator="[
              'oldPassword',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入原密码',
                    trigger: 'blur',
                  },
                ],
              },
            ]"
            :allow-clear="true"
            @blur="preBlur"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validator: newValid
                  },
                ],
                validateTrigger: 'onBlur'
              },
            ]"
            :allow-clear="true"
            @blur="newBlur"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
            v-decorator="[
              'rePassword',
              {
                rules: [
                  {
                    required: true,
                    message: '请再次确认密码',
                  },
                  {
                    validator: renewValid
                  },
                ],
                validateTrigger: 'onBlur'
              },
            ]"
            :allow-clear="true"
            @blur="renewBlur"
          />
        </a-form-item>
      </a-form>
    </Modal>
  </div>
</template>

<script>
import { pwdReg } from '@/utils/reg.js'
import { changePassword, getPublicKeyApi } from '@/services/login'
import { removeAuthorization } from '@/utils/request'
import { RSAEncrypt } from '@/utils/secret.js'
export default {
  components: {
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      passwordVisible: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      form: this.$form.createForm(this)
    }
  },
  methods: {
    handleSubmit() {
      this.form.validateFields(['oldPassword', 'password', 'rePassword'], async(errors, values) => {
        if (!errors) {
          // 获取公钥
          const publickKey = await getPublicKeyApi({ algorithm: 'RSA-2048' })
          this.publicKey = publickKey.data.publicKey
          // 加密密码
          const encryptOldPassword = await RSAEncrypt(values.oldPassword, this.publicKey)
          const encryptPassword = await RSAEncrypt(values.password, this.publicKey)
          const encryptRePassword = await RSAEncrypt(values.rePassword, this.publicKey)

          const param = {
            oldPassword: encryptOldPassword,
            password: encryptPassword,
            rePassword: encryptRePassword
          }
          const res = await changePassword(param)
          if (res.data) {
            this.onClose()
            this.$message.success('修改密码成功')
            this.$router.push('/login')
            localStorage.clear()
            removeAuthorization()
          } else {
            this.$message.error(res.message)
          }
        }
      })
    },
    onClose() {
      this.passwordVisible = false
      this.form.resetFields()
    },
    preBlur() {
      const form = this.form
      form.validateFields(['oldPassword'], { force: true })
    },
    newBlur() {
      const form = this.form
      form.validateFields(['password'], { force: true })
    },
    renewBlur() {
      const form = this.form
      form.validateFields(['rePassword'], { force: true })
    },
    newValid(rule, value, callback) {
      // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/ // 数字或字母
      if (!value) {
        callback(new Error('请输入密码'))
      } else if (value && pwdReg.test(value)) {
        callback()
      } else {
        const arr = [{
          message: '请设置8-16位包含数字与大小写字母组成的新密码',
          field: 'password'
        }]
        this.form.setFields({ password: { value: value, errors: arr }})
      }
    },
    renewValid(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        const arr = [{
          message: '两次输入密码不一致，请重新输入!',
          field: 'rePassword'
        }]
        this.form.setFields({ rePassword: { value: value, errors: arr }})
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.drawer-bottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  /deep/.ant-btn:first-child {
    margin-right:16px
  }
}
.login-out{
  color: @error-color;
}
</style>
