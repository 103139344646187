/*
 * @Date: 2022-06-14 08:57:09
 * @descriotion:
 */
// 考勤-设置-假期
import { ATTENDACE } from '../api'
import { METHOD, request } from '@/utils/request'
export async function getAttendancePeriodSelectApi(params) {
  return request(`${ATTENDACE}/report/getAttendancePeriodSelect`, METHOD.GET, params)
}
