<template>
  <div class="cusCover">
    <div v-if="imgInfo.src" style="width: 100%;height: 390px">
      <img
        ref="img"
        class="cropperBox"
        :src="imgInfo.src"
        @load="imgLoad($event)"
      >
    </div>
    <a-upload-dragger
      v-else
      class="uploadBox"
      name="file"
      action="#"
      :before-upload="beforeUpload"
      :show-upload-list="false"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        点击或拖拽文件到此区域上传海报
      </p>
      <p class="ant-upload-hint">
        建议尺寸：{{ imgSize.width }}*{{ imgSize.height }}
      </p>
    </a-upload-dragger>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import { upload, getSignature } from '@/services/upload/index.js'
import 'cropperjs/dist/cropper.css'
export default {
  name: 'CusCover',
  props: {
    // 图片裁剪的尺寸
    imgSize: {
      type: Object,
      required: true,
      default: () => {
        return {
          width: 0,
          height: 0
        }
      }
    },
    // 允许上传类型的数组
    typeList: {
      type: Array,
      required: true,
      default: () => []
    },
    // 文件大小的限制
    fileSize: {
      type: Number,
      default: 0
    },
    // 0 不敏感 1敏感
    sensitiveFile: {
      default: '1',
      type: String,
      require: true
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL,
      imgInfo: {},
      cropper: null
    }
  },
  methods: {
    // 上传前验证
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        // 判断类型
        const { name, size } = file
        const { typeList, fileSize } = this
        const singleType = name.toLowerCase().split('.')
        const typeInclude = typeList.includes(singleType[1])
        console.log('xxxxxxxx', typeList, singleType)
        if (!typeInclude) {
          this.$message.error('上传图片格式错误, 请重新选择')
          return reject(false)
        }
        // 判断大小
        const isLt2M = size / 1024 / 1024 < fileSize
        if (!isLt2M) {
          this.$message.error(`图片最大上传${fileSize}M`)
          return reject(false)
        }
        const _URL = window.URL || window.webkitURL
        this.imgInfo = file
        this.imgInfo.src = _URL.createObjectURL(file)
        resolve(true)
      })
    },
    imgLoad(e) {
      this.$nextTick(() => {
        this.cropper = new Cropper(this.$refs.img, {
          viewMode: 1,
          dragMode: 'none',
          aspectRatio: this.imgSize.width / this.imgSize.height, // 裁剪区默认正方形
          autoCropArea: 0.8, // 自动调整裁剪图片
          cropBoxResizable: false, // 裁剪区缩放
          background: false, // 关闭默认背景
          preview: '.previewBox',
          zoomOnWheel: false,
          ready: () => {
            this.cropper.setCropBoxData({
              width: this.imgSize.width,
              height: this.imgSize.height
            })
          }
        })
      })
    },
    // 自定义上传的方法
    async customRequest(file) {
      const form = new FormData()
      // const realindex = this.fileList.length - 1
      form.append('file', file)
      form.append('fileName', file.name)
      form.append('fileType', 'other')
      form.append('sensitiveFile', this.sensitiveFile)
      const res = await upload(form)
      if (res.code === 100) {
        let url = ''
        if (this.sensitiveFile === '1') {
          const signatureRes = await getSignature({
            id: res.data.fileId
          })
          if (signatureRes.code === 100) {
            url = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${res.data.fileId}&width=${291}&height=${125}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
          }
        } else {
          url = `${this.basePath}/hrsaas-storage/image/thumbnail?id=${res.data.fileId}&width=${291}&height=${125}`
        }
        return {
          url,
          fileId: res.data.fileId
        }
      }
    },
    uploadAndGetData() {
      const imgCropper = this.cropper.getCroppedCanvas({
        imageSmoothingQuality: 'high'
      }).toDataURL('image/png')
      const file = this.dataURLtoBlob(imgCropper, this.imgInfo.name)
      return this.customRequest(file)
    },
    dataURLtoBlob(dataurl, name) { // base64转file
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], name, {
        type: mime
      })
    }
  }
}
</script>

<style lang="less" scoped>
.uploadBox{
  display: block;
  height: 390px;
}
</style>
