<!--
 * @Date: 2022-07-08 15:41
 * @descriotion:
-->
<template>
  <div>
    <!-- 薪酬表单 -->
    <CbModal v-model="visible" :title="type==='download'?'下载报表':'预览报表'" :z-index="6" @ok="handleOk" @cancel="cancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <!-- 年月 季度 年份 -->
        <!-- 年月 -->
        <a-form-model-item v-if="isMonth" ref="date" label="年月" prop="date">
          <a-month-picker
            v-model="form.date"
            placeholder="请选择年月"
            :disabled-date="disabledMonth"
            @change="onMonthChange"
          />
        </a-form-model-item>
        <!-- 季度 -->
        <template v-if="isQuarter">
          <a-form-model-item ref="year" label="年" prop="year">
            <div class="year-quarter">
              <CbYearPicker v-model="form.year" :disabled-date="disabledYear" :allow-clear="false" class="CbYearPicker" />
            </div>
          </a-form-model-item>
          <a-form-model-item ref="quarter" label="季度" prop="quarter">
            <a-select v-model="form.quarter">
              <a-select-option v-for="item in quarterList" :key="item.value" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
        <!-- 年 -->
        <a-form-model-item v-if="isYear" ref="date" label="年" prop="date">
          <CbYearPicker v-model="form.date" :disabled-date="disabledYearfull" :allow-clear="false" class="CbYearPicker" />
        </a-form-model-item>
        <a-form-model-item v-if="isMonth || isYear" label="报税主体">
          <dictionariesInput v-model="form.taxpaySubjectStaffIds" mode="multiple" parameter="getEndyearBounsList" />
        </a-form-model-item>
        <a-form-model-item v-if="isMonth" label="薪资组">
          <dictionariesInput v-model="form.payrollGroupIds" mode="multiple" parameter="listGroup" />
        </a-form-model-item>
        <a-form-model-item v-if="isQuarter" label="部门">
          <depTreeSelect v-model="form.orgIds" :multiple="true" />
        </a-form-model-item>
        <a-form-model-item label="排序">
          <a-radio-group v-model="form.sort" name="radioGroup">
            <a-radio value="orgName">
              按部门排序
            </a-radio>
            <a-radio value="staffNum">
              按工号排序
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </CbModal>
  </div>
</template>
<script>
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import depTreeSelect from '@/components/CbPerDepSelection/index.js'
import { getAttendancePeriodSelectApi } from '@/services/attendance/reportForm.js'
import moment from 'moment'
import CbYearPicker from '@/components/CbYearPicker'
export default {
  components: {
    dictionariesInput,
    CbYearPicker,
    CbModal: () => import('@/components/CbModal/index.vue')
  },
  mixins: [depTreeSelect],
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        taxpaySubjectStaffIds: [],
        payrollGroupIds: [],
        sort: 'orgName',
        date: '',
        yearTime: '',
        year: '',
        orgIds: []
      },
      quarterList: [
        {
          value: 0,
          name: '第一季度'
        },
        {
          value: 1,
          name: '第二季度'
        },
        {
          value: 2,
          name: '第三季度'
        },
        {
          value: 3,
          name: '第四季度'
        }
      ],
      rules: {
        date: [{ required: true, message: '请选择年月', trigger: 'change' }],
        year: [{ required: true, message: '请选择年', trigger: 'change' }],
        quarter: [{ required: true, message: '请选择季度', trigger: 'change' }]
      },
      visible: false,
      periodList: [],
      type: '',
      yearTime: '',
      title: ''
    }
  },
  computed: {
    isMonth() {
      return this.title.includes('月度')
    },
    isQuarter() {
      return this.title.includes('季度')
    },
    isYear() {
      return this.title.includes('年度')
    }
  },
  methods: {
    async getPeriodList() {
      const res = await getAttendancePeriodSelectApi()
      this.periodList = res.data
      this.periodList.forEach(item => {
        item.yearMonth = moment(item.yearMonth).format('YYYY-MM')
      })
    },
    show(type, title = '') {
      this.type = type
      this.title = title || ''
      this.getPeriodList()
      this.visible = true
      this.$nextTick(() => {
        const form = {
          taxpaySubjectStaffIds: [],
          payrollGroupIds: [],
          sort: 'orgName',
          date: '',
          yearTime: '',
          year: '',
          orgIds: []
        }
        this.$set(this, 'form', form)
      })
    },
    // 考勤
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const { sort, orgIds, taxpaySubjectStaffIds, date, year, quarter } = this.form
          const arr = orgIds.map(item => {
            return item.value
          })
          const params = {
            date: date,
            orgIds: arr.join(','),
            sort,
            taxpaySubjectStaffIds: taxpaySubjectStaffIds.join(','),
            year,
            quarter
          }
          if (this.type === 'download') {
            this.$emit('download', params)
            this.visible = false
          } else {
            this.$emit('preview', params)
            this.visible = false
          }
        }
      })
    },
    cancel() {
      this.$refs.ruleForm.resetFields()
      Object.assign(this.$data, this.$options.data())
      this.visible = false
    },
    disabledMonth(value) {
      const nextMonth = moment().format('YYYY-MM')
      console.log('moment()', value.format('YYYY-MM'), moment(nextMonth).isSameOrAfter(value))
      return moment(value).isAfter(nextMonth)
    },
    onMonthChange(value) {
      this.form.date = moment(value).format('YYYYMM')
    },
    disabledYear(data) {
      const nowDate = moment().format('YYYY')
      return { flag: moment(data).isAfter(nowDate), msg: '不能选择未来的年份' }
    },
    disabledYearfull(data) {
      const nowDate = moment().format('YYYY')
      return { flag: moment(data).isAfter(nowDate), msg: '只能选择历史年份' }
    }
  }
}
</script>
<style lang="less" scoped>
.year-quarter {
  display: flex;
  align-items: center;
  .CbYearPicker {
    margin-right: 20px;
  }
}
/deep/ .ant-select-selection__placeholder {
  margin-left: 0;
}
/deep/ .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 12px;
}
/deep/ span.ant-radio + * {
  color: @sc-grey-100;
}
</style>
