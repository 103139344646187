<template>
  <div class="search">
    <a-input
      v-model="value"
      :placeholder="placeholder"
      @focus="focus"
      @blur="blur"
      @pressEnter="searchValueChange"
    >
      <a-icon slot="prefix" type="search" />
    </a-input>
    <div v-if="valueIcon" class="icon" @click="visibleCallback">
      <a-icon type="close-circle" theme="filled" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',
  model: {
    prop: 'searchValue',
    event: 'getSearchValue'
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    }
  },
  data() {
    return {
      value: '',
      valueIcon: false
    }
  },
  watch: {
    searchValue(val) {
      this.value = val
    },
    value(val) {
      if (val) {
        this.valueIcon = true
      } else {
        this.valueIcon = false
      }
    }
  },
  methods: {
    searchValueChange(data) {
      // data.target._value
      this.$emit('getSearchValue', this.value)
    },
    focus() {
      this.$emit('focus')
    },
    blur() {
      if (!this.value && !this.valueIcon) {
        this.$emit('visibleCallback')
      }
    },
    visibleCallback() {
      this.value = ''
      this.$emit('visibleCallback')
    }
  }
}
</script>

<style lang="less" scoped>
  .search {
    position: relative;
  }
  .icon {
    position: absolute;
    top: 5px;
    right: 4px;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    color: #cccccc;
    cursor: pointer;
  }
</style>
