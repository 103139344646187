<template>
  <div>
    <!--  顶部  -->
    <div class="department_top">
      <div>
        <span>已选：</span>
        <span class="department_top_margin">人员{{ menuListData ? menuListData.length : 0 }}个</span>
      </div>
      <div class="clear_all" @click="delAllCallBack">全部清空</div>
    </div>
    <!--  列表  -->
    <div class="department_count">
      <div v-for="(item) in menuListData" :key="item.id" class="department_count_data">
        <!--  人员  -->
        <div class="department_count_datas">
          <div class="department_count_info">
            <CbAvater
              :on-job-id="item.onJobId"
              :avater-name="item.staffName"
              size="30"
            />
            <div class="jobNumber">{{ item.phoneNum }}</div>
          </div>
          <a-icon type="close-circle" theme="filled" class="filled_icon" @click="delCallBack(item.staffId)" />
        </div>
      </div>
    </div>
    <!--  按钮  -->
    <div class="button">
      <a-button class="button_close" @click="closeModel">取消</a-button>
      <a-button type="primary" @click="perModelConfirm">确认</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataList',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    menuListData: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  methods: {
    delCallBack(id) {
      this.$emit('delCallBack', id)
    },
    delAllCallBack() {
      this.$emit('delAllCallBack')
    },
    closeModel() {
      this.$emit('closeModel')
    },
    perModelConfirm() {
      this.$emit('perModelConfirm')
    }
  }
}
</script>

<style lang="less" scoped>
  .department_top {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: @sc-grey-100;
    margin-top: 5px;
    .department_top_margin {
      margin-left: 15px;
    }
    .clear_all{
      cursor: pointer;
      color: #F34856;
    }
  }
  .department_count {
    width: 100%;
    height: 466px;
    overflow: auto;
    margin-top: 5px;
    .department_count_data {
      margin: 11px 0;
      .department_count_datas {
        display: flex;
        justify-content: space-between;
        color: @sc-grey-100;
        font-size: 14px;
        margin: 0 0 26px 0;
        .department_count_info{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          /deep/.box .user-name{
            margin-left: 10px;
          }
        }
        .filled_icon {
          color: @sc-greyBg-50;
          margin-top: 5px;
        }
        .jobNumber {
          margin-right: 20px;
          width: 100px;
          color: @sc-grey-60;
      }
      }
    }
  }
  .button {
    text-align: right;
    .button_close {
      margin-right: 12px;
    }
     /deep/ .ant-btn {
      padding: 5px 10px;
      span {
        .font-size(14px);
      }
    }
  }
</style>
