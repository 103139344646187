<template>
  <div>
    <a-tree
      v-model="checkedKeys"
      :checkable="true"
      :expanded-keys="expandedKeys"
      :tree-data="treeData"
      @expand="onExpand"
      @check="onCheck"
    >
      <template slot="custom" slot-scope="item">
        <!--  部门      -->
        <div v-if="item.identification === 'dep'" class="tree_box">
          <div>
            <img :src="imgBase" alt="" class="icon">
          </div>
          <div class="title">{{ item.orgName }}</div>
        </div>
        <!--  人员      -->
        <div v-else class="tree_box">
          <CbAvater
            :on-job-id="item.onJobId"
            :avater-name="item.staffName"
            size="30"
          />
          <div class="jobNumber">{{ item.phoneNum }}</div>
        </div>
      </template>
    </a-tree>
  </div>
</template>

<script>
import { getTree } from './index.js'
export default {
  name: 'Tree',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater.vue')
  },
  props: {
    menuListDataList: {
      type: Array,
      default: () => []
    },
    treeDataList: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      imgBase: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAAqCAYAAAD4Uag9AAAABHNCSVQICAgIfAhkiAAAA/tJREFUaIHdmj1QG3cQxX/vxJdHzJg0uAsybWDGpVxFGddRzinThBSp7RKTwkpD6AJtKIwb0hnRhzHu7CpkYCZV4o/SRRx7IALju5fikDkSkHSIjzOv0q32Tu/p9r+7/70Th+DmncanNpWAuGSpdJjPWcGwhuL79enBtSznKX0QTjYqkheAkdSFnwptnQzNbBBcNS7uHT43vl3/oVjv8NwEX05t1WzdFdqK0bwUrOPg5akwzgLFw8TRdXAVMSx74cFM8Zu2p8H7O/XQ8CRw348W53KHWkGmaO1+C77RibgAQPKC0FZeRQEkvHpnQSuWJsKpxkQr/yC8sxUCIzGaz6uog+idxbyUXWvlFQTiGoAUrJ8Jr5OAtAKMhJON0lEuPTbJl5kSxW4VKHdFrhvYV/bSXgl4dphLD6gE2uj8qrvT4HHDC8HTLikeD+Iv4HeC6O+jXHqyXXH3K/C45O+Xpou17tidLoKM/p8Djx7kXBRkEaaoDB60tHB6dE4OnQtznCSLvu2OWprzRoZQ9BjwW7320ZELNk/oMHm8GwWuCGbS1nCyUZL2G+bzgPu3D/2zO82KNwBi60AYSr4HVLpm1wW008/NO/88t3WPge25psgOhXkMeF6fufSsaUmqviuGJ7iw3PLHA4o4Hj02+3bscFlyjbf9t8Opzc/q04Nr7YUpHsYexcwdMOPbAFLPT9CmazFA4fHxqbehCIt2NA7Rd0KrYe1VqX3yiKPrCbf9MAxrr4aQJ0AbudizAVJhXSrMgi7zdiBsL0yUjd/UZy6tvre9HQhBl41+OUWu2eEkKoRbC5MpgscESwftvoV5KXpWTpPn8aANzFBLYbHelQEM+2E42agA1/a2DnlECdoW6Pg6QHqAIsUTyYdCvsIQkkSHByWvtg5FtAl7yYJmitfXoJW8JI007HgcII6D1sJQkCzGnf6lcLJxV/JD0KbcO38GPDNDkNTKge02dcyFxxD/bFyVXAH9gYI5k9fZyH4/20Hn0bsoWEzGX2wlxTav8Cj4PmTo7vM+wUo6D7CCVci+g84tpCRxQLQGmWce+YWTMHzTfHhxce4Y+kTo1+bxxRCWKsxN04UQli7MTduFECY8DpDegQQAxlfPidMJISnMaUuAWRMUkzj98LBXv64IFtL2wEGcGBx/cfa0uoeIqvD/QVNQnx5cQyxDXG1W7w8GisqIMmYuPWiC5hrr25kAvZaiqWSUnX/Y0TiObhleeGCn9t/v3z9cD6c2r+FgWfAxaF0Ey6bw5EzZdgLFwxBXk6Wj11ZUOexViYOvQ9ReDWm7v4a4lXL5k9xsU5xeKo/cvxMeNXLXYcZwslEK5NCighk6FY7HhOTVGNfbvdDyL6TXmYt7pBqKAAAAAElFTkSuQmCC',
      basePath: process.env.VUE_APP_API_BASE_URL,
      expandedKeys: [], // 展开指定节点
      checkedKeys: [],
      countList: [], // 右侧菜单列表
      treeData: []
    }
  },
  watch: {
    menuListDataList: {
      handler: function(val) {
        const arr = []
        for (let i = 0; i < val.length; i++) {
          arr.push(val[i].staffId)
        }
        this.checkedKeys = arr
        this.countList = val
      },
      immediate: true
    },
    treeDataList: {
      handler: function(val) {
        this.treeData = getTree(val)
      },
      immediate: true
    }
  },
  methods: {
    // 需要展开的节点
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    // 选中
    onCheck(val, data) {
      //  选择有值
      if (val && val.length > 0) {
        // 选中
        if (data.checked) {
          if (data.node.dataRef.identification === 'peo') {
            this.$emit('getRightMenu', data.node.dataRef, 'peo')
          } else {
            if (data.node.dataRef.children && data.node.dataRef.children.length > 0) {
              this.$emit('getRightMenu', data.node.dataRef.children, 'dep')
            }
          }
        } else {
          if (data.node.dataRef.identification === 'peo') {
            this.$emit('getRightMenu', data.node.dataRef.staffId, 'peoDel')
          } else {
            this.$emit('getRightMenu', data.node.dataRef.children, 'depDel')
          }
        }
      } else {
        this.$emit('getRightMenu')
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .tree_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon {
      width: 26px;
      height: 20px;
      margin: 0 10px 0 10px;
    }
    /deep/ .box{
      width: 190px;
      margin: 0 10px 0 12px;
      .user-name{
        margin-left: 8px;
      }
    }
    .jobNumber{
      color: @sc-grey-60;
    }
  }
  /deep/ .ant-tree li {
    margin: 15px 0;
    padding: 0;
  }
</style>
