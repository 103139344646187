<!--
 * @Date: 2022-06-13 15:57:44
 * @descriotion:
-->
<template>
  <div>
    <!-- 考勤模块弹窗 -->
    <CbModal v-model="visible" size="normal" :title="type==='download'?'下载报表':'预览报表'" :z-index="6" @ok="handleOk" @cancel="cancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="periodId" label="年月" prop="periodId">
          <a-select v-model="form.periodId" placeholder="请选择">
            <a-select-option v-for="item in periodList" :key="item.periodId" :value="item.periodId">
              {{ item.yearMonth }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合同公司">
          <dictionariesInput v-model="form.contractCo" mode="multiple" parameter="contract_company" />
        </a-form-model-item>
        <a-form-model-item label="部门">
          <depTreeSelect v-model="form.orgIds" :multiple="true" />
        </a-form-model-item>
      </a-form-model>
    </CbModal>
  </div>
</template>
<script>
import dictionariesInput from '@/components/CbDropDownInput/dictionariesInput/index'
import depTreeSelect from '@/components/CbPerDepSelection/index.js'
import { getAttendancePeriodSelectApi } from '@/services/attendance/reportForm.js'
import moment from 'moment'
export default {
  components: {
    dictionariesInput,
    CbModal: () => import('@/components/CbModal/index.vue')
  },
  mixins: [depTreeSelect],
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        periodId: '',
        contractCo: [],
        orgIds: []
      },
      rules: {
        periodId: [{ required: true, message: '请选择年月', trigger: 'change' }]
      },
      visible: false,
      periodList: [],
      type: ''
    }
  },
  mounted() {

  },
  methods: {
    async getPeriodList() {
      const res = await getAttendancePeriodSelectApi()
      this.periodList = res.data
      this.periodList.forEach(item => {
        item.yearMonth = moment(item.yearMonth).format('YYYY-MM')
      })
    },
    show(type) {
      this.type = type
      this.getPeriodList()
      this.visible = true
    },
    // 考勤
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const arr = []
          this.form.orgIds.map(item => {
            arr.push(item.value)
          })
          if (this.type === 'download') {
            this.$emit('download', { periodId: this.form.periodId, orgIds: arr.join(','), contractCo: this.form.contractCo.join(',') })
            this.cancel()
          } else {
            this.$emit('preview', { periodId: this.form.periodId, orgIds: arr.join(','), contractCo: this.form.contractCo.join(',') })
            this.cancel()
          }
        }
      })
    },
    cancel() {
      this.$refs.ruleForm.resetFields()
      Object.assign(this.$data, this.$options.data())
      this.visible = false
    },
    disabledDate(current) {
      console.log(moment().endOf('day'))
      return current && current > moment().startOf('day')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 12px;
}
/deep/ .ant-select-selection__placeholder {
  margin-left: 0;
}
</style>
