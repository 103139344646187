import { render, staticRenderFns } from "./attendanceModel.vue?vue&type=template&id=65daf087&scoped=true&"
import script from "./attendanceModel.vue?vue&type=script&lang=js&"
export * from "./attendanceModel.vue?vue&type=script&lang=js&"
import style0 from "./attendanceModel.vue?vue&type=style&index=0&id=65daf087&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65daf087",
  null
  
)

export default component.exports