// 数组递归添加自定义scopedSlots
export function getTree(treeData) {
  treeData.forEach(item => {
    item['scopedSlots'] = { title: 'custom' }
    if (item.identification === 'peo') {
      item['key'] = item.staffId
    } else {
      item['key'] = item.orgId
    }
    if (item.children && item.children.length > 0) {
      getTree(item.children)
    }
  })
  return treeData
}

// 数组递归判断是人员还是部门
export function getTypeJudgment(treeData) {
  const obj = []
  function getPeopleList(data) {
    data.forEach(item => {
      if (item.identification === 'peo') {
        obj.push(item)
      } else {
        if (item.children && item.children.length > 0) {
          getPeopleList(item.children)
        }
      }
    })
  }
  getPeopleList(treeData)
  return obj
}

// 寻找数据
export function getIdData(dataList, id) {
  let list = []
  function find(targetData) {
    for (let i = 0; i < targetData.length; i++) {
      if (targetData[i].staffId === id) {
        list = targetData[i]
        break
      } else {
        if (targetData[i].children && targetData[i].children.length > 0) {
          find(targetData[i].children, id)
        }
      }
    }
  }
  find(dataList)
  return list
}

// 寻找数据回显
export function getDataEcho(dataList, id) {
  let list = {}
  function find(targetData) {
    for (let i = 0; i < targetData.length; i++) {
      if (targetData[i].onJobId === id && targetData[i].identification === 'peo') {
        list = targetData[i]
        break
      } else {
        if (targetData[i].children && targetData[i].children.length > 0) {
          find(targetData[i].children, id)
        }
      }
    }
  }
  find(dataList)
  return list
}
